<template lang="pug">
.remove-background-image-confirmation
  CanvasModal(
    :isShow="showRemoveBgConfirmation"
    :extraClass="extraClass"
    @closeOverlay="closeOverlay"
    :maxWidth="1100"
  )
    div.confirmation__header
      div.header--left
        svg(
          v-if="isDarkMode"
          width="24"
          height="24"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        )
          rect(y="3.87695" width="15.508" height="15.508" rx="1" fill="white")
          rect(opacity="0.5" x="5.15234" y="0.5" width="14.508" height="14.508" rx="0.5" stroke="white")
          path(d="M14 16.5556V6.44444C14 5.65 13.35 5 12.5556 5H2.44444C1.65 5 1 5.65 1 6.44444V16.5556C1 17.35 1.65 18 2.44444 18H12.5556C13.35 18 14 17.35 14 16.5556ZM5.26111 12.93L6.77778 14.7572L9.01667 11.8756C9.16111 11.6878 9.45 11.6878 9.59444 11.8828L12.1294 15.2628C12.31 15.5011 12.1367 15.8406 11.8406 15.8406H3.18111C2.87778 15.8406 2.71167 15.4939 2.89944 15.2556L4.69778 12.9444C4.835 12.7567 5.10944 12.7494 5.26111 12.93Z" fill="#364866")
        svg(
          v-else
          width="24"
          height="24"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        )
          rect(y="3.87695" width="15.508" height="15.508" rx="1" fill="#182C4F")
          rect(opacity="0.5" x="5.15234" y="0.5" width="14.508" height="14.508" rx="0.5" stroke="#182C4F")
          path(d="M14 16.5556V6.44444C14 5.65 13.35 5 12.5556 5H2.44444C1.65 5 1 5.65 1 6.44444V16.5556C1 17.35 1.65 18 2.44444 18H12.5556C13.35 18 14 17.35 14 16.5556ZM5.26111 12.93L6.77778 14.7572L9.01667 11.8756C9.16111 11.6878 9.45 11.6878 9.59444 11.8828L12.1294 15.2628C12.31 15.5011 12.1367 15.8406 11.8406 15.8406H3.18111C2.87778 15.8406 2.71167 15.4939 2.89944 15.2556L4.69778 12.9444C4.835 12.7567 5.10944 12.7494 5.26111 12.93V12.93Z" fill="#FCFCFD")
        h4.header-title AI BG Remover
        button.remove-bg--show-guide(
          type='button'
          @click="openGuide"
        )
          svg(width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg")
            circle(cx="10.5682" cy="10.5682" r="10.0682" :stroke="isDarkMode ? '#E1E4E8': '#182c4f'")
            path(d="M10.6026 7.24629C11.336 7.24629 11.9226 7.39962 12.3626 7.70629C12.8093 8.00629 13.0326 8.44296 13.0326 9.01629C13.0326 9.59629 12.8593 10.0396 12.5126 10.3463C12.1726 10.6463 11.716 10.8263 11.1426 10.8863L11.0726 11.8263H9.85264L9.79264 10.3163C10.426 10.2496 10.8793 10.1296 11.1526 9.95629C11.426 9.77629 11.5626 9.52962 11.5626 9.21629C11.5626 8.95629 11.4726 8.75629 11.2926 8.61629C11.1193 8.46962 10.8593 8.39629 10.5126 8.39629C10.166 8.39629 9.88264 8.49629 9.66264 8.69629C9.44931 8.88962 9.32931 9.16629 9.30264 9.52629L8.00264 8.98629C8.08931 8.45296 8.34931 8.02962 8.78264 7.71629C9.22264 7.40296 9.82931 7.24629 10.6026 7.24629ZM10.4626 12.4763C10.7293 12.4763 10.9393 12.5463 11.0926 12.6863C11.246 12.8263 11.3226 13.0163 11.3226 13.2563C11.3226 13.4963 11.246 13.6863 11.0926 13.8263C10.9393 13.9663 10.7293 14.0363 10.4626 14.0363C10.196 14.0363 9.98598 13.9663 9.83264 13.8263C9.68598 13.6863 9.61264 13.4963 9.61264 13.2563C9.61264 13.0163 9.68598 12.8263 9.83264 12.6863C9.98598 12.5463 10.196 12.4763 10.4626 12.4763Z" fill="#E1E4E8")
      div.header--right
        span.credits-remaining
          strong.credits__bold {{ removeBgCredits.free }}
          | {{ freeCreditsRemainingSuffix }}

          svg.icon(
            width="19"
            height="19"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @mouseenter="showCreditInfo"
            @mouseleave="hideCreditInfo"
          )
            circle(cx="10.5682" cy="10.5682" r="10.0682" :stroke="isDarkMode ? '#E1E4E8': '#0d55cf'")
            path(d="M10.5665 8.22629C9.99988 8.22629 9.71655 7.98296 9.71655 7.49629C9.71655 7.00296 9.99988 6.75629 10.5665 6.75629C11.1332 6.75629 11.4165 7.00296 11.4165 7.49629C11.4165 7.98296 11.1332 8.22629 10.5665 8.22629ZM11.2465 13.9463H9.87655V8.94629H11.2465V13.9463Z" fill="#E1E4E8")

          transition(
            name="fade"
          )
            .credits-info(
              v-show="isCreditInfoVisible"
            )
              h3.title Credits Reload
              p 40 monthly credits will be given on the 1st of every month. Monthly credits can stack up a maximum of 120 if unused.
        span.credits-remaining.credits-remaining--paid(
          :class="{'credits--no-padding': !isShowBuyMoreButton}"
        )
          strong.credits__bold {{ removeBgCredits.purchased }}
          | {{ paidCreditsRemainingSuffix }}
          BaseButton.btn-purchase-more-credits(
            v-if="isShowBuyMoreButton"
            :plain-hover="true"
            @click="setShowRemoveBgPurchaseOverlay(true)"
          ) Buy more

    .remove-bg-preloader(
      v-if="isConverting"
      :class="{'is-dark': isDarkMode}"
    )
      BasePreloader(
        text="Analyzing Image"
      )

    .image-preview
      img(
        :src="imagePreview"
      )

    BaseButton.remove-background__button(
      v-if="hasCredits"
      :is-primary="true"
      :is-canvas="true"
      :is-tall="false"
      :disabled="isConverting"
      @click="showConfirmation = true"
    ) Remove BG

    RemoveBgGuide(
      :showGuide="showGuide"
      @closeGuide="showGuide = false"
    )

  BaseDialog(
    :isShow="showConfirmation"
    text="Removing background will use <strong>1 credit</strong>.<br/>Continue removing background?"
    @confirm="confirm"
    @closeOverlay="showConfirmation = false"
    button1Text="Yes, remove it"
  )
</template>

<script>
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import RemoveBgGuide from '@/components/ProjectCanvas/PageOverlay/children/RemoveBgGuide.vue';

import Api from '@/services/api/Api';
import helperMixin from '@/components/mixins/helper-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'RemoveBgConfirm',
  components: {
    RemoveBgGuide,
  },
  mixins: [helperMixin, darkModeMixin],
  data() {
    return {
      isConverting: false,
      showConfirmation: false,
      showGuide: false,
      isShaken: false,
      isCreditInfoVisible: false,
    };
  },
  computed: {
    ...mapState(['showRemoveBgConfirmation', 'isTemplate']),
    ...mapState('canvasElements', ['removeBgElement']),
    ...mapState('team', ['teamId']),
    ...mapState('userData', ['removeBgCredits']),
    ...mapGetters('team', ['isTeamMember', 'isTeamAdmin', 'isInTeamWorkspace']),
    ...mapGetters('canvasElements', ['getProjectId']),
    ...mapGetters('userData', ['isPayingUser']),
    freeCreditsRemainingSuffix() {
      let text = 'Monthly Credit';
      if (this.removeBgCredits.free > 1) text += 's';

      return `${text} Left`;
    },
    paidCreditsRemainingSuffix() {
      let text = 'Paid Credit';
      if (this.removeBgCredits.purchased > 1) text += 's';

      return `${text}`;
    },
    hasCredits() {
      return this.removeBgCredits.free + this.removeBgCredits.purchased > 0;
    },
    imagePreview() {
      if (typeof this.removeBgElement.data === 'undefined') return '';
      return this.removeBgElement.data.urlHd || this.removeBgElement.data.url;
    },
    formImports() {
      const params = {};
      params.is_template = this.isTemplate;
      params.projectId = this.getProjectId || '';

      if (typeof this.removeBgElement.element_id === 'number' && !this.isTeamElement) {
        params.element_id = this.removeBgElement.element_id;
      } else {
        params.file_url = this.removeBgElement.data.urlHd || this.removeBgElement.data.url;
      }

      return params;
    },
    extraClass() {
      const cssClass = [];

      if (this.isShaken) cssClass.push('is-shaken');

      return cssClass.join(' ');
    },
    isTeamElement() {
      return !!this.removeBgElement.data.team_element_origin;
    },
    isShowBuyMoreButton() {
      return this.isTeamAdmin || (this.isPayingUser && !this.isInTeamWorkspace);
    },
  },
  methods: {
    ...mapActions('canvasElements', ['confirmedRemoveBgElement']),
    ...mapActions('team', ['fetchTeamBrands']),
    ...mapMutations(['setShowRemoveBgConfirmation', 'setShowRemoveBgPurchaseOverlay']),
    ...mapMutations('userData', ['setRemoveBgCredits']),
    closeOverlay() {
      if (!this.isConverting) {
        this.setShowRemoveBgConfirmation(false);
        this.showGuide = false;
      } else {
        this.isShaken = true;
        setTimeout(() => {
          this.isShaken = false;
        }, 500);
      }
    },
    openGuide() {
      this.showGuide = true;
    },
    confirm() {
      this.showConfirmation = false;
      this.isConverting = true;
      const isTeam = this.isTeamElement && !this.isTeamMember;
      const params = {
        imports: this.formImports,
      };

      let headers = {};
      if (isTeam) {
        headers = {
          is_logo: true,
          team_id: this.removeBgElement.data.team_element_origin.team_id,
          brand_id: this.removeBgElement.data.team_element_origin.brand_id,
        };
      }

      Api.removeBg(params, headers)
        .then((response) => {
          if (response.data.success) {
            const { user_files } = response.data.results;
            if (user_files) {
              this.alertSuccess(
                '',
                'Remove background success, updating canvas...',
                5000,
                'TopCenterNotif',
              );
              this.setRemoveBgCredits(response.data.results.remove_bg_credits);
              this.confirmedRemoveBgElement(user_files).then(() => {
                this.isConverting = false;
                this.$root.$emit('remove-bg-done');
                this.closeOverlay();
              });

              if (isTeam) this.fetchTeamBrands(this.teamId);
            }
          } else {
            this.isConverting = false;
            let err = typeof response.data.message !== 'undefined' && response.data.message !== ''
              ? response.data.message
              : 'Oops, something went wrong. Try again later.';
            err += '<button type="button" class="remove-bg-learn-more">learn more</button>';
            this.alertWarn('', err, 5000, 'TopCenterNotif');
          }
        })
        .catch(() => {
          this.isConverting = false;
          this.alertError(
            '',
            'Oops, something went wrong. Try again later. <button type="button" class="remove-bg-learn-more">learn more</button>',
            5000,
            'TopCenterNotif',
          );
        });
    },
    showCreditInfo() {
      this.isCreditInfoVisible = true;
    },
    hideCreditInfo() {
      this.isCreditInfoVisible = false;
    },
    removeBgLearnMoreEvent(event) {
      if (!event.target.matches('.remove-bg-learn-more')) return;
      event.preventDefault();
      this.openGuide();
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.removeBgLearnMoreEvent);
  },
  mounted() {
    document.addEventListener('click', this.removeBgLearnMoreEvent);
  },
  watch: {
    removeBgElement(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss">
.remove-background-image-confirmation {
  .canvas-modal__overlay {
    background: rgba(0, 0, 0, 0.8);
  }

  .canvas-modal__content {
    background: var(--white-darkgrey700);
    width: 950px;

    .content {
      padding: 35px 60px;
      min-height: 500px;
    }
  }

  .canvas-modal__closebtn {
    color: var(--darkgrey-white) !important;
  }

  .confirmation__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header--left,
    .header--right {
      display: flex;
      align-items: center;
      color: var(--darkgrey-white);
    }

    .header-title {
      padding: 0;
      margin: 0;
      margin: 0 10px;
      font-size: 1.125rem;
      font-weight: 500;
    }

    .remove-bg--show-guide {
      font-size: 1.25rem;
      margin: 0;
      padding: 0;
      width: 22px;
      height: 22px;

      path {
        fill: var(--darkgrey-white) !important;
      }
    }
  }

  .remove-background__button {
    background: $blue2;
    padding: 13px 70px;

    &:hover {
      color: $blue2;
    }
  }

  .credits-remaining {
    background: var(--lightergreen-darkgrey5);
    color: var(--blue800-white);
    margin-right: 10px;
    font-size: 0.75em;
    padding: 8px 10px;
    border-radius: 4px;
    position: relative;
    height: 36px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--paid {
      background: var(--sectionblue-darkgrey5);
      padding-right: 75px;

      &.credits--no-padding {
        padding-right: 10px;
        width: 120px;
      }
    }

    .icon {
      margin: 0;
      margin-left: 8px;
      margin-right: -4px;
      margin-top: -2px;
      cursor: pointer;

      path {
        fill: var(--blue800-white) !important;
      }
    }

    .credits__bold {
      margin-right: 4px;
    }

    .btn-purchase-more-credits {
      position: absolute;
      right: 4px;
      font-size: 0.75rem;
      padding: 8px;
      background: var(--blue800-green);

      &:hover {
        color: var(--blue800-green);
      }
    }
  }

  .credits-info {
    position: absolute;
    z-index: 10;
    background: var(--white-darkgrey5);
    width: 340px;
    padding: 20px;
    border-radius: $componentBorderRadius;
    box-shadow: 0px 3px 14px rgba(10, 31, 68, 0.1), 0px 0px 20px rgba(10, 31, 68, 0.08);
    font-size: 1rem;
    text-align: left;
    top: 40px;
    left: 0;
    line-height: 1.3;

    .title {
      margin: 0;
      margin-bottom: 10px;
      font-size: 1rem;
      font-weight: 600;
      color: var(--darkgrey-white);
    }

    p {
      margin: 0;
      color: $grey;
      font-size: 0.8125rem;
      font-weight: normal;
    }
  }

  .remove-bg-preloader {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    background: rgba($light, 0.8);

    &.is-dark {
      background: rgba($darkGrey700, 0.8);

      .preloader__text {
        color: $light;
      }
    }
  }

  .image-preview {
    width: 100%;
    max-height: 400px;
    min-height: 250px;
    margin: 30px auto;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .content--bottom {
    justify-content: center;

    .btn-cancel {
      margin-left: 20px;
    }
  }
}

.remove-bg-learn-more {
  position: absolute;
  right: 65px;
  text-transform: uppercase;
  font-weight: 600;
}
</style>

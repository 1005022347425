<template lang="pug">
.remove-bg-guide
  CanvasModal(
    :isShow="showGuide"
    @closeOverlay="$emit('closeGuide')"
    :maxWidth="1100"
  )
    h4.guide__header How it works ?
    div.remove-bg-guide--hero
      p
        | We use sophisticated AI technology to detect foreground layers and separate them from the background. To improve the results
        | we have several additional algorithms in place, for instance to improve fine details and prevent color contamination.
      p
        | You can upload any image in <strong>JPG or PNG format</strong> with a file size up to <strong>12 megabytes</strong>.
      p
        | All photos that have <strong>a subject that is clearly meant to be the foreground</strong> are supported.
        | For instance, most photos of people, products, animals, cars and other objects work.

    div.remove-bg-guide--section
      div.remove-bg-guide--section__recommendations
        h3.title--recommendation
          | General Recommendation
        ul
          li
            | <strong>Contrast:</strong> Images taken under good lighting conditions and with a high contrast between foreground
            | and background give better results.
          li
            | <strong>Alignment:</strong> Make sure to upload images with the correct orientation. Results are best if they match gravity
            | (i.e. the ground is at the bottom of the image).
          li
            | <strong>Prefer plain backgrounds:</strong> Blurry and single-color backgrounds are easier to remove than sharp backgrounds
            | with many details.
          li
            | <strong>Sharp foreground:</strong> If the foreground is blurry it might get removed. If only the edges are blurry, they
            | will still be blurry in the cutout (which may or may not be an issue).
      div.remove-bg-guide--section__examples
        img.bg-remover-guide__image(src="@/assets/images/remove-bg/recommendation_general.jpg")
        i.icon-tick
        | Plain background and sharp foreground are preferred.
</template>

<script>
export default {
  name: 'RemoveBgGuide',
  props: {
    showGuide: { type: Boolean, default: false },
  },
  methods: {
    closeOverlay() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss">
.remove-bg-guide {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba($darkGrey600, 0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--lightgrey600-darkgrey4);
  }

  .canvas-modal__overlay {
    background: transparent;
    z-index: 1;
  }

  .canvas-modal__closebtn {
    color: var(--darkgrey-white) !important;
  }

  .canvas-modal__content {
    background: var(--white-darkgrey900) !important;
    height: 640px;
    padding: 60px 30px 40px 50px;

    .content {
      padding: 0 !important;
      padding-right: 20px !important;
      overflow: auto !important;
    }
  }

  .canvas-modal__main {
    font-size: 0.85em;
    text-align: left;

    .remove-bg-guide--hero {
      background-color: var(--sectionblue-darkgrey5);
      color: var(--blue800-white);
      padding: 15px 30px;
      line-height: 1.5;
    }

    .remove-bg-guide--section {
      display: flex;
      margin-top: 20px;

      .remove-bg-guide--section__recommendations {
        width: calc(calc(100% / 2) + 100px);
        margin-right: 100px;
        color: var(--darkgrey-white);

        .title--recommendation {
          font-size: 1.25em;
          font-weight: 600;
        }

        ul {
          padding-inline-start: 20px;

          li {
            margin-bottom: 15px;
            line-height: 1.75;
          }
        }
      }

      .remove-bg-guide--section__examples {
        width: calc(100% / 2);
        margin-top: 20px;
        color: var(--darkgrey-white);

        img {
          width: 100%;
          margin-top: 20px;
        }

        i {
          margin-right: 5px;
          vertical-align: text-top;
          font-size: 1.25em;

          &.icon-tick {
            color: $green;
          }

          &.icon-close {
            color: $red;
          }

          &.icon-alert {
            color: $amber;
          }
        }
      }
    }
  }

  .guide__header {
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--darkgrey-white);
    margin-top: 0;
    margin-bottom: 15px;
  }
}
</style>
